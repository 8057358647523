import "./Header.css"

import { ReactComponent as Vk} from'../../../img/vk.svg'
import { ReactComponent as Inst} from'../../../img/inst.svg'
import { ReactComponent as Tg} from'../../../img/tg.svg'
import { ReactComponent as Git} from'../../../img/git.svg'

export default function Header() {
    return <div className='HomeMenuCommunication'>
        <div>COMMUNICATION</div>

        <div className="HomeMenuContactsLinks">
            <a href='https://vk.com/xop.enotov' target="_blank" rel="noreferrer">
                <div className="HomeMenuContactsLink" style={{paddingTop: "2px", background: 'var(--blue-vk)'}}>
                    <Vk width="22px" height="22px"/>
                </div>
            </a>

            <a href='https://vk.com/xop_enotov' target="_blank" rel="noreferrer">
                <div className="HomeMenuContactsLink" style={{paddingTop: "2px", background: 'var(--black-vk)'}}>
                    <Vk width="22px" height="22px"/>
                </div>
            </a>

            <a href='https://www.instagram.com/xop_enotov/' target="_blank" rel="noreferrer">
                <div className="HomeMenuContactsLink" style={{ background: 'var(--inst)' }}>
                    <Inst width="18px" height="18px"/>
                </div>
            </a>

            <a href='https://t.me/XOP_EH0T0B' target="_blank" rel="noreferrer">
                <div className="HomeMenuContactsLink" style={{paddingTop: "1px", background: 'var(--tg)'}}>
                    <Tg width="18px" height="18px"/>
                </div>
            </a>

            <a href='https://github.com/XOP-EHOTOB' target="_blank" rel="noreferrer">
                <div className="HomeMenuContactsLink" style={{paddingTop: "1px",  background: 'var(--git)'}}>
                    <Git width="18px" height="18px"/>
                </div>
            </a>
        </div>
    </div>
}